<template>
    <div class="homepage-wrapper">
<!--        <div id="preloader"></div>-->
        <Header/>
        <!-- header end -->
        <!-- Start Slider Area -->
        <div class="slide-area slide-area-3 fix heading-area" data-stellar-background-ratio="0.6" style="background: #222e3d !important;">
            <div class="display-table">
                <div class="display-table-cell">
                    <div class="container">
                        <div class="row">
                            <div class="text-center">
                              <br>
                              <br>
                              <h1 style="color:#ffffff" class="mt-3">About</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Slider Area -->
        <!-- Start About Area -->
        <div class="about-area bg-grey-color fix area-padding">
            <div class="container">
                <div class="row">
                    <div class="support-all">
                        <!-- Start services -->
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <h1 class="text-dark mb-2">Do more with cryptocurrency</h1>
                          <p class="mt-2 mb-2">At TransCrypt, you get to <router-link to="/trading-home">invest </router-link> in crypto, <router-link to="/home">cash out</router-link>  your crypto
                            or even <router-link to="/send-money">send money</router-link> using crypto to your loved ones. Founded
                              by Eric Barbier, a cryptocurrency owner and an expat living in
                              Singapore, he found it inconvenient to perform crypto
                              transactions on different platforms, which was why he came up
                              with TransCrypt, a one-stop crypto platform for people to do
                              more with cryptocurrency.</p>
                        </div>
                        <!-- Start services -->
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <img src="/assets/remittance-assets/about-eric-barbier.png" alt="CEO-image">
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <!-- End About Area -->


        <div class="about-area bg-color fix" style="padding-bottom : 50px;">

            <div class="row">
                <div class="support-all">
                    <!-- Start services -->
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <div class="row slogan-left">
                            <h2 class="ml-5 p-5" style="color:#ffffff">Quick, easy and simple. Invest in crypto, cash out crypto, send money with crypto - all on TransCrypt</h2>
                        </div>
                    </div>
                    <!-- Start services -->
                    <div class="col-md-6 col-sm-6 col-xs-12 p-5">
                        <div class="row">
                            <div class="col-md-6 col-sm-6 col-xs-12">
                                <div class="text-center icon-with-text-container">
                                    <a class="support-images p-2 icon-with-bg-color"><img
                                            src="/assets/remittance-assets/about-globe-icon.png" alt=""></a>
                                    <h3 class="text-bold mt-2">
                                        Over 120
                                    </h3>
                                    <h5>
                                        countries
                                    </h5>
                                </div>

                            </div>
                            <div class="col-md-6 col-sm-6 col-xs-12">
                                <div class="text-center icon-with-text-container">
                                    <a class="support-images p-2 icon-with-bg-color"><img
                                            src="/assets/remittance-assets/about-multi-currency-icon.png" alt=""></a>
                                    <h3 class="text-bold mt-2">
                                        Multi-currency
                                    </h3>
                                    <h5>
                                        borderless transaction
                                    </h5>

                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>

        </div>

        <div class="about-area bg-color fix area-padding">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 col-sm-12 col-xs-12">
                        <div class="section-headline text-center">
                            <h3>Why choose Transcrypt?</h3>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="support-all">
                        <div class="row">
                            <!-- Start services -->
                            <div class="col-md-6 col-sm-6 col-xs-12">
                                <div class="about-support-services wow ">
                                    <a class="support-images"><img
                                            src="/assets/remittance-assets/about-accessible-icon.png" alt=""></a>
                                    <div class="support-content">
                                        <h4>Accessible and convenient</h4>
                                        <p>TransCrypt aims to improve everyday living with
                                          cryptocurrency, making it convenient and accessible for those
                                          unbanked and those with no access to crypto services.</p>
                                    </div>
                                </div>
                            </div>
                            <!-- Start services -->
                            <div class="col-md-6 col-sm-6 col-xs-12">
                                <div class="about-support-services ">
                                    <a class="support-images"><img
                                            src="/assets/remittance-assets/about-quick-icon.png" alt=""></a>
                                    <div class="support-content">
                                        <h4>Quick and seamless</h4>
                                        <p>Carrying out crypto transactions should be straightforward.
                                          TransCrypt was developed to resolve real-life crypto
                                          transactions issues, making them quick and seamless.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <!-- Start services -->
                            <div class="col-md-6 col-sm-6 col-xs-12">
                                <div class="about-support-services ">
                                    <a class="support-images"><img
                                            src="/assets/remittance-assets/about-low-cost-icon.png" alt=""></a>
                                    <div class="support-content">
                                        <h4>Low cost and fair rates</h4>
                                        <p>We want our customers to enjoy the best of our service at
                                          the lowest cost possible. To make this happen, TransCrypt
                                          gets the best exchange rate from a number of exchanges.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <!-- Start services -->
                            <div class="col-md-6 col-sm-6 col-xs-12">
                                <div class="about-support-services">
                                    <a class="support-images"><img
                                            src="/assets/remittance-assets/about-safe-icon.png" alt=""></a>
                                    <div class="support-content">
                                        <h4>Safe and secure</h4>
                                        <p>Compliant with global AML and KYC standards, TransCrypt
                                          runs on a proprietary technology that keeps your money
                                          safe and secure.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Start brand Banner area -->
        <div class="brand-area area-padding fix" data-stellar-background-ratio="0.6">
            <div class="container">
                <div class="row trading-flex-center" >
                    <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 getting-start-box">
                        <div class="brand-text text-center">
                            <div class="text-white fw-700 text-xlarge">Cash out your
                              crypto quickly and seamlessly</div>
                            <router-link to="/home"><a class="hire-btn">Get started</a></router-link>
                        </div>
                    </div>
                  <div class=" col-lg-4  col-md-4 col-sm-12 col-xs-12 getting-start-box">
                    <div class="brand-text text-center">
                      <div class="text-white fw-700 text-xlarge">Send money to
                        your loved ones
                        with crypto</div>
                      <router-link to="/send-money"><a class="hire-btn">Get started</a></router-link>
                    </div>
                  </div>
                  <div class=" col-lg-4 col-md-4 col-sm-12 col-xs-12 getting-start-box">
                    <div class="brand-text text-center">
                      <div class="text-white fw-700 text-xlarge">Invest in cryptocurrency
                        safely</div>
                      <router-link to="/trading-home"><a class="hire-btn">Get started</a></router-link>
                    </div>
                  </div>
                </div>
            </div>
        </div>
        <!-- End brand Banner area -->


        <!-- Start Footer Area -->
        <Footer/>
        <!-- End Footer Area -->
    </div>
</template>
<script>
    import Footer from "../layouts/HomepageFooter"
    import Header from "../layouts/HomepageHeader"

    export default {
        name: 'about',
        components: {Footer,Header},
        data() {
            return {
                debugConsole : false,
            }
        },
        metaInfo: {
            title: 'About TransCrypt | Money Transfer with Cryptocurrency',
            meta: [
                {
                    name: 'description',
                    content: ' Quick, Easy, and Seamless, TransCrypt is a one-stop cryptocurrency remittance service for people to make borderless money transfer with cryptocurrency.'
                }
            ]
        },
        mounted() {
            if(this.debugConsole)  console.debug("About Page Mounted");
            require('@/assets/js/main.js');

            //check if mean container is loaded
           if(this.debugConsole) console.log("Mean container loaded", window.jQuery('.mean-container'))
            if(window.jQuery('.mean-container').length === 0)
            {
                window.jQuery('nav#dropdown').meanmenu()
            }

        },
        methods: {
            goToHomepage() {
                this.$router.push('/home');
            },
            goToLogin(){
                this.$router.push('/account/login')
            },
            goToRegisterPage(){
                this.$router.push('/account/register');
            }
        }
    }

</script>
<style scoped>



</style>
